import { clearToken } from '@iso/lib/helpers/utility';
import { createBrowserHistory } from 'history';
import { all, fork, put, takeEvery } from 'redux-saga/effects';

import actions from './actions';

const history = createBrowserHistory();
const fakeApiCall = false;

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload, email }) {
    if (payload) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload,
        profile: 'Profile',
        email: email,
      });
    } else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* changedPassword() {
  yield takeEvery('CHANGE_PASSWORD', function* () {
    localStorage.setItem('changed_password', 'true');
  });
}

export function* acceptTerms() {
  yield takeEvery('ACCEPT_TERMS', function* () {
    localStorage.setItem('is_accepted', 'true');
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    // console.log('takeEveryLoginSuccess', payload);
    localStorage.setItem('id_token', payload.payload.token);
    localStorage.setItem('tipo_usuario', payload.payload.type);
    localStorage.setItem('is_accepted', payload.payload.is_accepted);
    localStorage.setItem('changed_password', payload.payload.changed_password);
    localStorage.setItem('last_email', payload.email);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    localStorage.clear();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    let payload = {
      token: localStorage.getItem('id_token'),
      type: localStorage.getItem('tipo_usuario'),
      is_accepted: localStorage.getItem('is_accepted'),
      changed_password: localStorage.getItem('changed_password'),
      email: localStorage.getItem('last_email'),
    };
    // console.log('checkauth', payload);
    if (payload.token && payload.type) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: payload,
        profile: 'Profile',
        email: payload.email,
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(changedPassword),
    fork(acceptTerms),
  ]);
}

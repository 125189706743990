import { Spin } from 'antd';
import React from 'react';

import LoaderComponent from './loader.style';

export default Loader => (
  <LoaderComponent>
    <Spin size='large' />
  </LoaderComponent>
);

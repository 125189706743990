import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.log(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>Algo deu errado! Por favor, atualize a página e tente novamente.</p>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import './app.css';

import GlobalStyles from '@iso/assets/styles/globalStyle';
import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react';
import { Provider } from 'react-redux';

import AppProvider from './AppProvider';
import siteConfig from './config/site.config';
import Boot from './redux/boot';
import { store } from './redux/store';
import Routes from './router';

function App() {
  registerLicense(siteConfig.registerLicence);

  return (
    <Provider store={store}>
      <AppProvider>
        <GlobalStyles />
        <Routes />
      </AppProvider>
    </Provider>
  );
}

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;

import brasilIcon from '../../assets/images/bandeiras/brasil.png';
import espanhaIcon from '../../assets/images/bandeiras/espanha.png';
import logo from '../../assets/images/logo.png';
import logoLogin from '../../assets/images/logoLogin.png';
import logoMenu from '../../assets/images/logoMenu.png';

const Images = {
  logo,
  logoMenu,
  logoLogin,
  brasilIcon,
  espanhaIcon,
};

export default Images;

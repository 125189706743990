import themes from '@iso/config/theme/theme.config';
import { ConfigProvider } from 'antd';
import antdPtBr from 'antd/lib/locale-provider/pt_BR';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

export default function AppProvider({ children }) {
  return (
    <ConfigProvider locale={antdPtBr}>
      <IntlProvider locale={'pt_BR'}>
        <ThemeProvider theme={themes.defaultTheme}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}

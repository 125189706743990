import actions from './actions';

const initState = { idToken: null, tipoUsuario: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      // console.log('ENTREI NO LOGIN SUCCESS', action);
      return {
        ...state,
        idToken: action.payload.token,
        tipoUsuario: action.payload.type,
        lastEmail: action.email,
        is_accepted: action.payload.is_accepted,
        changed_password: action.payload.changed_password,
      };
    case actions.LOGOUT:
      return initState;
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        changed_password: true,
      };
    case actions.ACCEPT_TERMS:
      return {
        ...state,
        is_accepted: true,
      };
    default:
      return state;
  }
}

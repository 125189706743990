const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (payload, email) => ({
    type: actions.LOGIN_REQUEST,
    payload,
    email,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
  changePassword: () => ({
    type: actions.CHANGE_PASSWORD,
  }),
  acceptTerms: () => ({
    type: actions.ACCEPT_TERMS,
  }),
};
export default actions;
